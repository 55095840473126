<template>
    <div>
        <b-sidebar id="sidebar-changeUserPass" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header
            right>
            <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h3 class="mb-0">
                        Change User Password
                    </h3>
                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="20" @click="hide" />
                </div>

                <h5 class="m-2">Name : {{ userData.name }} </h5>
                <h5 class="m-2">Email : {{ userData.email }}</h5>

                <validation-observer ref="changeUserPassRules">
                    <b-form @submit="onSubmit" class="m-2" id="FORMID">

                        <b-form-group label="New Password" label-for="account-password">
                            <validation-provider #default="{ errors }" name="Password" vid="Password"
                                rules="required|password">
                                <b-form-input v-model="form.password" name="password" placeholder="new password"
                                    type="password" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="Confirm New Password" label-for="confirm-account-password">
                            <validation-provider #default="{ errors }" name="Confirm Password"
                                rules="required|confirmed:Password">
                                <b-form-input v-model="form.conPassword" name="password" placeholder="confirm new password"
                                    type="password" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-form-group>

                    </b-form>
                </validation-observer>
            </template>

            <template #footer>
                <div class="flex mb-2 ml-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" form="FORMID" variant="success" class="mr-2"
                        type="submit" @click.prevent="onSubmit">
                        Submit
                    </b-button>

                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-danger" form="FORMID"
                        @click="closeSidebar">
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormRadioGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    required, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
    components: {
        BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BSidebar, BFormRadioGroup,
        ValidationProvider, ValidationObserver, ToastificationContent
    },
    directives: {
        Ripple,
    },
    props: {
        userData: {
            type: Object
        },
    },
    data() {
        return {
            required, confirmed, password,
            form: {
                password: '',
                conPassword: ''
            }
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault()
            this.$refs.changeUserPassRules.validate().then(success => {
                if (success) {
                    try {

                        axios({
                            method: 'post',
                            url: process.env.VUE_APP_API + '/change-user-pass',
                            data: {
                                'password': this.form.password,
                                'userId': this.userData.id
                            }
                        })
                            .then(res => {
                                if (res.data.success) {
                                    this.closeSidebar()
                                    this.$toast
                                        ({
                                            component: ToastificationContent,
                                            props: {
                                                title: 'User Password Updated',
                                                icon: 'AlertOctagonIcon',
                                                text: '',
                                                variant: 'success',
                                            },
                                        },
                                            {
                                                position: 'top-right'
                                            })
                                }
                                else {
                                    this.$toast
                                        ({
                                            component: ToastificationContent,
                                            props: {
                                                title: 'Failed',
                                                icon: 'AlertOctagonIcon',
                                                text: '',
                                                variant: 'danger',
                                            },
                                        },
                                            {
                                                position: 'top-left'
                                            })
                                }
                            })
                    }
                    catch (error) {
                        console.log(error)
                    }
                }

            })
        },
        closeSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-changeUserPass')
        },
    }

}
</script>
