<template>
  <div class="position-relative">
    <b-row>
      <b-col md="6">
        <!-- <span v-if="checkPermission('all_access')"> -->

        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" v-b-toggle.sidebar-addNewUser>Add New
          User</b-button>
        <!-- </span> -->
      </b-col>

      <b-col md="6">
        <b-row>
          <b-col md="8">
            <b-form-group   label-cols-sm="2" label-align-sm="right" label-size="md" label-for="filterInput">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group   label-cols-sm="4" label-align-sm="right" label-size="sm"
              label-for="perPageInput">
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                @change="savePerPage(perPage)" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-table
  v-if="users.length > 0"
  :items="users"
  :fields="fields"
  responsive="sm"
  :sticky-header="false"
  striped
  small
  id="user-table"
  :per-page="perPage"
  :current-page="currentPage"
  :filter="filter"
  :filter-included-fields="filterOn"
  @filtered="onFiltered"
>
      <template #cell(name)="row">
        <span>{{ row.item.name }}</span>
      </template>

      <template #cell(email)="row">
        <span>{{ row.item.email }}</span>
      </template>

      <template #cell(role)="row">
        <span v-if="row.item.roles.length > 0">{{ row.item.roles[0].display_name }}</span>
      </template>
      <!-- v-if="row.item.roles.length > 0" -->
      <!-- <template #cell(role)="row">
        <div class="text-nowrap">
          <feather-icon :icon="resolveUserRoleIcon(row.item.roles[0].display_name)" size="18" class="mr-50"
            :class="`text-${resolveUserRoleVariant(row.item.roles[0].display_name)}`" />
          <span class=" -text-top text-capitalize">{{ row.item.roles[0].display_name }}</span>
        </div>
      </template> -->

      <template #cell(status)="row">
        <b-badge pill :variant="`light-${resolveUserStatusVariant(row.item.active)}`" class="text-capitalize">
          {{ resolveUserStatus(row.item.active) }}
        </b-badge>
      </template>

      <template #cell(date_created)="row">
        <span>{{ row.item.created_at | formatDate }}</span>
      </template>

      <template #cell(action)="row">
        <!-- <span v-if="checkPermission('all_access')"> -->

        <span>

          <span v-if="row.item.active">
            <feather-icon icon="SlashIcon" size="16" class="text-body align-middle mr-25 cursor-pointer"
              v-b-tooltip.hover.v-danger title="Block User" @click="block_unblockUser(row.item.id, false)" />
          </span>

          <span v-if="!row.item.active">
            <feather-icon icon="UnlockIcon" size="16" class="text-body align-middle mr-25 cursor-pointer"
              v-b-tooltip.hover.v-warning title="Unblock User" @click="block_unblockUser(row.item.id, true)" />
          </span>

          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
            </template>

            <b-dropdown-item @click="changeUserPass(row.item)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Change User Password</span>
            </b-dropdown-item>

            <b-dropdown-item v-b-toggle.sidebar-editUser @click="sendUserEditProps(row.item)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Edit</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="row.item.roles.length > 0 && row.item.roles[0].name != 'admin'"
              @click="changeUserRole(row.item.id)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Make Admin</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteUser(row.item.id)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
        <!-- </span> -->
      </template>
    </b-table>
    <div v-else class="text-center mt-4">{{ emptyUserMessage }}</div>
    <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" first-number last-number
      class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18" />
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18" />
      </template>
    </b-pagination>

    <add-new-user @refresh="getAllUsers" />

    <change-user-password :userData="userData" />
    <edit-user :editUser="editUser" @refresh="getAllUsers" />

    <b-overlay :show="loader" no-wrap spinner-variant="primary"></b-overlay>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  VBToggle,
  BOverlay,
  BRow,
  BCol,
  BPagination,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BBadge,
  VBTooltip
} from "bootstrap-vue";
import axios from "axios";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddNewUser from "./AddNewUser.vue";
import ChangeUserPassword from './ChangeUserPassword';
import User from '../../Helpers/User'
import EditUser from "./EditUser.vue";


export default {
  components: {
    BTable,
    BButton,
    BRow,
    BCol,
    BPagination,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    ToastificationContent,
    AddNewUser,
    ChangeUserPassword,
    EditUser
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      perPage: "25",
      pageOptions: [25, 50, 100, 200],
      currentPage: 1,
      emptyUserMessage: "No Data Found.",
      totalRows: 1,
      filter: null,
      filterOn: [],
      fields: ["name", "email", "role", "status", "date_created", { key: "action", label: "Action", tdClass: 'actionClass' }],
      users: [],
      loader: false,
      userData: {},
      editUser: {}
    };
  },
  mounted() {
    this.perPage = this.getPerPage() ? this.getPerPage() : "25";
  },
  created() {
    this.getAllUsers();
  },

  methods: {
    sendUserEditProps(param) {
      // console.log('param',param)
      this.editUser = param
    },
    checkPermission(permission) {

      return User.checkPermission(permission)
    },
    resolveUserStatus(status) {
      if (status == true) return 'active'
      if (status == false) return 'blocked'
    },
    resolveUserStatusVariant(status) {
      if (status == true) return 'success'
      if (status == false) return 'secondary'
      return 'primary'
    },
    resolveUserRoleIcon(role) {

      if (role === 'Manager') return 'Edit2Icon'
      if (role === 'Admin') return 'ServerIcon'
      return 'UserIcon'
    },
    resolveUserRoleVariant(role) {
      if (role === 'Manager') return 'warning'
      if (role === 'Admin') return 'danger'
      return 'primary'
    },
    getAllUsers() {
      this.loader = true;
      axios
        .get(process.env.VUE_APP_API + "/all-users")
        .then((res) => {
          this.loader = false;
          this.users = res["data"];
          console.log(this.users)
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    changeUserPass(userdata) {
      this.userData = userdata
      this.$root.$emit('bv::toggle::collapse', 'sidebar-changeUserPass')
    },
    deleteUser(userId) {
      if (userId) {
        this.loader = true
        axios({
          method: 'post',
          url: process.env.VUE_APP_API + '/delete-user',
          data: {
            'userId': userId,
          }
        })
          .then((res) => {
            if (res.data.success) {
              this.getAllUsers()
              this.loader = false
              this.$toast
                ({
                  component: ToastificationContent,
                  props: {
                    title: 'User Deleted',
                    icon: 'AlertOctagonIcon',
                    text: '',
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-right'
                  })
            }
            else {
              this.loader = false
              this.$toast
                ({
                  component: ToastificationContent,
                  props: {
                    title: 'Failed',
                    icon: 'AlertOctagonIcon',
                    text: '',
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-left'
                  })
            }
          })
          .catch((error) => {
            this.loader = false
            console.log(error);
          });

      }
    },
    changeUserRole(userId) {
      if (userId) {
        this.loader = true
        axios({
          method: 'post',
          url: process.env.VUE_APP_API + '/change-user-role',
          data: {
            'userId': userId
          }
        })
          .then((res) => {
            if (res.data.success) {
              this.getAllUsers()
              this.loader = false
            }
            else {
              this.loader = false
              this.$toast
                ({
                  component: ToastificationContent,
                  props: {
                    title: 'Failed',
                    icon: 'AlertOctagonIcon',
                    text: res.data.message,
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-right'
                  })
            }
          })
          .catch((error) => {
            this.loader = false
            console.log(error);
          });
      }

    },
    block_unblockUser(userId, action) {

      if (userId) {
        this.loader = true
        axios({
          method: 'post',
          url: process.env.VUE_APP_API + '/block-unblock-user',
          data: {
            'userId': userId,
            'action': action
          }
        })
          .then((res) => {
            if (res.data.success) {
              this.getAllUsers()
              this.loader = false
            }
            else {
              this.loader = false
              this.$toast
                ({
                  component: ToastificationContent,
                  props: {
                    title: 'Failed',
                    icon: 'AlertOctagonIcon',
                    text: '',
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-left'
                  })
            }
          })
          .catch((error) => {
            this.loader = false
            console.log(error);
          });
      }

    }
  },

  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD , hh:mm:ss");
      }
    },
  },
  computed: {
    rows() {
      return this.users.length;
    },
  }
};
</script>

<style>
.actionClass {
  max-width: 300px;

}

td {
  padding: 10px;
}
</style>
