<template>
    <div>
        <!-- <span v-if="checkPermission('all_access')"> -->
        <b-sidebar id="sidebar-editUser" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right>
            <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h3 class="mb-0">
                        Edit User :
                    </h3>
                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
                </div>

                <validation-observer ref="editUserRules">
                    <b-form @submit="onSubmit" @reset="onReset" class="m-2" id="FORMID">

                        <b-form-group id="name" label="Name:" label-for="input-name">
                            <validation-provider #default="{ errors }" name="name" rules="required">
                                <b-form-input id="input-name" v-model="form.name" placeholder="Enter Name">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="E-mail" label-for="account-e-mail">
                            <validation-provider #default="{ errors }" name="Email" rules="required|email">
                                <b-form-input v-model="form.email" name="email" placeholder="Email" type="email" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group id="role" label="Roles:" label-for="input-role">
                            <b-form-radio-group v-model="form.selected_role" :options="role_options"
                                class="demo-inline-spacing" name="radio-inline" />
                        </b-form-group>

                    </b-form>
                </validation-observer>
            </template>
            <!-- <template #cell(role)="row">
                <span>{{ row.form.roles[0].display_name }}</span>
            </template> -->
            <template #footer>
                <div class="flex mb-2 ml-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" form="FORMID" variant="success" class="mr-2"
                        type="submit" @click.prevent="onSubmit">
                        Submit
                    </b-button>

                    <b-button type="reset" variant="danger" class="mr-2" form="FORMID">
                        Reset
                    </b-button>

                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-danger" form="FORMID"
                        @click="closeSidebar">
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-sidebar>
        <!-- </span> -->
    </div>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormRadioGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    required, email,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import User from '../../Helpers/User'

export default {
    components: {
        BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BSidebar, BFormRadioGroup,
        ValidationProvider, ValidationObserver, ToastificationContent
    },
    directives: {
        Ripple,
    },
    props: {
        editUser: {
            type: Object,
            required: true,
        }
    },
    watch: {
        editUser() {
            // console.log('this.edit props', this.editRole)
            this.form.id = this.editUser.id;

            this.form.name = this.editUser.name;
            this.form.email = this.editUser.email;
            this.form.selected_role = this.editUser.roles[0]['id'];

        }
    },
    data() {
        return {
            required, email,
            form: {
                id: '',
                name: '',
                email: '',
                selected_role: ''
            },
            role_options: [
            ],
        }
    },

    created() {
        this.getAllRoles();
    },
    methods: {
        checkPermission(permission) {

            return User.checkPermission(permission)
        },
        onReset(event) {
            event.preventDefault()
            this.clearForm()
        },
        getAllRoles() {
            this.loader = true;
            axios
                .get(process.env.VUE_APP_API + "/roles")
                .then((res) => {
                    this.loader = false;
                    this.role_options = res["data"].map((option) => ({
                        text: option.name, // Display name for the radio button
                        value: option.id, // Value for the radio button
                    }));
                })
                .catch((error) => {
                    this.loader = false;
                    console.log(error);
                });
        },
        clearForm() {
            this.form.name = '',
                this.form.email = ''
        },
        onSubmit(event) {
            this.$refs.editUserRules.validate().then(success => {
                console.log('this.form data', this.form)
                if (success) {
                    axios.post(process.env.VUE_APP_API + '/user-update', this.form)
                        .then(res => {
                            if (res.data.success) {
                                this.$toast
                                    ({
                                        component: ToastificationContent,
                                        props: {
                                            title: res.data.message,
                                            icon: 'AlertOctagonIcon',
                                            text: '',
                                            variant: 'success',
                                        },
                                    },
                                        {
                                            position: 'top-right'
                                        })
                                this.$emit('refresh')
                                this.closeSidebar()
                            }
                            else {
                                this.$toast
                                    ({
                                        component: ToastificationContent,
                                        props: {
                                            title: 'Failed',
                                            icon: 'AlertOctagonIcon',
                                            text: res.data.success,
                                            variant: 'danger',
                                        },
                                    },
                                        {
                                            position: 'top-left'
                                        })
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            })
        },
        closeSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-editUser')
            this.clearForm()
        },
    },
    computed: {
    }

}
</script>
